<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { z } from 'zod'

import { Base400Gray } from '~/components/typography'
import {
  BtnFacebook,
  BtnGoogle,
  BtnOrange,
  BtnSoundcloud,
} from '~/components/ui/Buttons'
import VTextInput from '~/components/ui/Inputs/VText.vue'
import VText from '~/components/ui/VText.vue'

import { useStartSso } from '~/composables/Auth/useStartSso'
import { useFieldValidation } from '~/composables/useFieldValidation'
import { useProvideAuthFetch } from '~/composables/useProvideAuthFetch'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useDraftStore } from '~/stores/draft'
import { useLoginStore } from '~/stores/login'
import { useUserStore } from '~/stores/user'

import { backupAccountSecret } from '~/helpers/SwapAccount'

import { provideAuthApiLogin, provideAuthApiSsoLogin } from '~/api-auth/Login'
import { provideGetNewToken } from '~/api-auth/Token'
import { providePushToCurrentUserFamily } from '~/api-core/Family/index'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

import type { ExistingUser } from '~/stores/login'
import type { AuthLogin, SsoProvider } from '~/types/Auth'

type Props = {
  user: ExistingUser
}

const props = defineProps<Props>()

const { coreFetch } = useProvideCoreFetch()
const { authFetch } = useProvideAuthFetch()
const { path } = useRoute()
const { authStartSso } = useStartSso()

const loading = ref(false)
const displayPassword = ref(false)
const displayPasswordAnnotation = ref(false)

const targetProvider = computed<SsoProvider | 'email'>(() => {
  if (props.user.is_facebook) return 'facebook'
  if (props.user.is_google) return 'google'
  if (props.user.is_soundcloud) return 'soundcloud'
  return 'email'
})

const fieldName = 'password'
const {
  value: passwordFieldValue,
  errorMessage: passwordFieldErrorMessage,
  validate: passwordFieldValidate,
} = useFieldValidation(
  fieldName,
  z
    .string()
    .refine((password) => targetProvider.value !== 'email')
    .or(z.string().min(8)),
  { syncVModel: true, validateOnValueUpdate: false, initialValue: '' },
)

const { id: ORIGIN_USER_ID } = storeToRefs(useUserStore())

const btnComponent = computed<
  | typeof BtnOrange
  | typeof BtnFacebook
  | typeof BtnGoogle
  | typeof BtnSoundcloud
>(() => {
  switch (targetProvider.value) {
    case 'facebook':
      return BtnFacebook
    case 'google':
      return BtnGoogle
    case 'soundcloud':
      return BtnSoundcloud
    case 'email':
      return BtnOrange
  }
})

const passwordInputType = computed<'text' | 'password'>(() =>
  displayPassword.value ? 'text' : 'password',
)

const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { RESET: DRAFT_RESET } = useDraftStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

function authEmailLogin() {
  const loginWithEmailPassword = provideAuthApiLogin(authFetch)

  return loginWithEmailPassword({
    email: props.user.email,
    password: passwordFieldValue.value,
  })
}
function authSsoLogin(provider: SsoProvider) {
  return new Promise<AuthLogin>((resolve, reject) => {
    authStartSso(provider, (code) => {
      const loginToAuthWithSso = provideAuthApiSsoLogin(authFetch)
      loginToAuthWithSso(provider, code).then(resolve).catch(reject)
    })
  })
}

function handleSuccesfulSwap() {
  DRAFT_RESET()
  LOGIN_SET_DISPLAY(false)
  reloadNuxtApp()
}

async function logToAuth(provider: SsoProvider | 'email') {
  const { access: originJwt } = await provideGetNewToken(authFetch)()
  const { access: targetJwt } = await (provider === 'email'
    ? authEmailLogin()
    : authSsoLogin(provider))

  const logoutCore = provideLogoutSession(coreFetch)
  const logToCoreWithJwt = provideLoginSession(coreFetch)

  await providePushToCurrentUserFamily(coreFetch)(targetJwt)
  await logoutCore()
  const targetUser = await logToCoreWithJwt(targetJwt)

  await Promise.allSettled([
    backupAccountSecret(ORIGIN_USER_ID.value, originJwt),
    backupAccountSecret(targetUser.id, targetJwt),
  ])

  handleSuccesfulSwap()
}
async function handleBtnClick(): Promise<void> {
  const { valid } = await passwordFieldValidate()

  if (loading.value || !valid) return

  loading.value = true
  try {
    await logToAuth(targetProvider.value)
  } catch (_) {
    loading.value = false
  }
}

function requestSignup() {
  if (path.includes('/band/signup/referral/')) LOGIN_SET_DISPLAY(false)
  else BAND_SIGNUP_SET_DISPLAY(true)
  // Will close login if open
}
</script>

<template>
  <div class="tw-w-relative">
    <div class="tw-grid tw-grid-cols-1 tw-gap-6">
      <div
        v-if="!user.email_disabled"
        class="tw-mb-8 tw-grid tw-grid-cols-1 tw-gap-4"
      >
        <Base400Gray class="tw-block tw-text-center">{{
          $t('auth.existingUserLogin.subtitle')
        }}</Base400Gray>
      </div>
      <img
        :src="user.picture"
        class="tw-mx-auto tw-h-32 tw-w-32 tw-overflow-hidden tw-rounded-full tw-object-contain tw-object-center"
      />
      <div>
        <Base400Gray class="tw-block tw-text-center">{{
          user.email
        }}</Base400Gray>
      </div>

      <section v-if="!user.email_disabled" class="tw-grid tw-gap-6">
        <div v-if="targetProvider === 'email'" class="tw-relative">
          <VTextInput
            v-model="passwordFieldValue"
            hide-right-picto
            :type="passwordInputType"
            :label="$t('auth.login.label.password')"
            :placeholder="$t('auth.login.placeholder.password')"
            :display-right-check="false"
            :validity="!passwordFieldErrorMessage"
            @focus="displayPasswordAnnotation = true"
            @blur="displayPasswordAnnotation = false"
          />
          <transition name="scale" mode="out-in">
            <div
              :key="displayPassword.toString()"
              class="sightIcon"
              @click="displayPassword = !displayPassword"
            >
              <i
                class="fas"
                :class="{
                  'fa-eye-slash': displayPassword,
                  'fa-eye': !displayPassword,
                }"
              />
            </div>
          </transition>
        </div>
        <component :is="btnComponent" class="tw-mb-6" @click="handleBtnClick">{{
          $t('common.confirm')
        }}</component>
      </section>

      <section v-else class="tw-grid tw-gap-6">
        <VText
          cfg="sans/16/medium"
          color="red-500"
          class="tw-block tw-text-center"
        >
          {{ $t('errors.input.disabledEmail') }}
        </VText>
        <BtnOrange class="tw-mb-6 tw-block" @click="requestSignup">
          {{ $t('auth.existingUserLogin.createNewAccount') }}
        </BtnOrange>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sightIcon {
  @apply tw-absolute tw-cursor-pointer tw-text-base tw-text-discrete2;

  top: 42px;
  right: 16px;
}

button.btn {
  @apply tw-w-full;
}
</style>
